import { useState } from "react";
import { Card } from "antd";
import "../styles/propinfo.css";
import "antd/dist/antd.css";
import { Steps } from "antd";
import pic from "../assets/unnamed.png";
import locpic from "../assets/ec-location@2x.png";
import photoheadpic from "../assets/ec-photos@2x.png";
// import { Upload } from 'antd';
import { Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { NavLink } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload, message, Spin } from "antd";
import axios from "axios";
import { event } from "jquery";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useContext } from "react";
import { PropertyContext } from "../../HB Components/propertyContext/PropertyContext";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default function Photos() {
  const {
    propImages,
    addToPropImages,
    propImagespath,
    addToPropImagespath,
    basic,
    onboardapi,
    logo_img
  } = useContext(PropertyContext);
  const description = "This is a description.";
  const location = useLocation();
  const navigate = useNavigate();
  const [imgfilenamelist, setimgfilenamelist] = useState("");
  const options = [
    {
      value: "Burns Bay Road",
    },
    {
      value: "Downing Street",
    },
    {
      value: "Wall Street",
    },
  ];
  const [propphoto, setpropphoto] = useState([]);
  const [errorphoto, seterrorphoto] = useState("");
  const [errorphotofilesize, seterrorphotofilesize] = useState("");

  const handleChange = (value) => {
   
  };

  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2 mb!");
    }
    return isLt2M;
  };
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([
    // {
    //   uid: '1',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://endpoint887127.azureedge.net/clogo/Tata_Capital.jpg',
    // },
    // {
    //   uid: '-1',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-2',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-3',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-4',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-xxx',
    //   percent: 50,
    //   name: 'image.png',
    //   status: 'uploading',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
    // {
    //   uid: '-5',
    //   name: 'image.png',
    //   status: 'error',
    // },
  ]);
  const [open, setOpen] = useState(false);
  const [file, setfile] = useState("");
  const [file1, setfile1] = useState("");
  const [file2, setfile2] = useState("");
  const [filename, setfilename] = useState();
  // const [Imagepath, setImagepath] = useState([]);
  const [imagegreaterthan, setimagegreaterthan] = useState(false);
  const [openerror, setopenerror] = useState(false);
  const handleCancel = () => setPreviewOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataChange, setDataChange] = useState("");
  const [openprevious, setOpenprevious] = useState(false);
  const [errormessagearr, setmessagearr] = useState([]);
  const [Openerorimg, setOpenerrorimg] = useState(false);
  const [messagearrimg, setmessagearrimg] = useState([]);

  let filesizeerror = false;
  // const [propphoto,setpropphoto]=useState("")
  let Imagepath1 = [];
  const handleCancelbtn = () => {
    
    setOpen(false);
  };

  const handleCancelbtnerror = () => {
    
    setopenerror(false);
  };

  const removefile = (e) => {
    setimagegreaterthan(false);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
      
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload{" "}
        <small>
          <br />
          (.jpg, .jpeg, .png) <br />
          (Maximum upload size limit 2mb)
        </small>
        <span className="m-red">*</span>
      </div>
    </div>
  );

  useEffect(() => {
    if (basic[0]?.propertyname === "" || basic[0]?.propertyname === undefined) {
      navigate("/");
    }
  }, []);

  // const fileinsert = () => {

  //   let filedata = document.querySelector(".ant-upload");

  //   let remove_error = document.querySelectorAll(".border_red");

  //   remove_error.forEach((item) => item.classList.remove("border_red"));

  //   if (fileList.length === 0) {
  //     setopenerror(true);
  //     seterrorphotofilesize("");
  //     seterrorphoto("* Minimum one property image needed & maximum three images allowed");
  //     let fileuploadeddata = document.querySelector(
  //       ".ant-upload-list-picture-card-container"
  //     );
  //     if (!fileuploadeddata) {
  //       filedata?.classList.add("border_red");
  //     }
  //   } else if (imagegreaterthan === true) {
  //     setopenerror(true);
  //     seterrorphoto("");

  //     seterrorphotofilesize("* Image should be less than 2 mb");
  //   } else {
  //     addToPropImages(fileList,Imagepath1);
  //     //localStorage.setItem("Propertyimages", JSON .stringify(fileList));
  //    setIsLoading(true);

  //     var form = document.getElementById("formimg");
  //     const formData = new FormData(form);
  //     // const formData2 = new FormData(form);
  //     // const formData3 = new FormData(form);

  //   for (let i = 0; i < fileList.length; i++) {
  //     const element = fileList[i];
  //     formData.append("file", fileList[i]?.originFileObj || propImages[i]?.originFileObj);
  //   }
  //     if(fileList.length > 0)
  //     {
  //      
  //       //  http://localhost:3000/api/imageuploadmultiple

  //          let response = axios
  //       .post(
  //         // "https://hotelonboardtestapi.staysimplyfied.com/API/imageuploadmultiple",
  //        "http://localhost:3000/api/imageuploadmultiple",
  //         formData
  //       )
  //       .then( (res) => {
  //        
  //         //setImagepath((pre) => [...pre,res.data]);
  //         Imagepath1.push({path:res.data.path,name:res.data.filename})
  //         setIsLoading(false);
  //         if(res.data.message==="Success")
  //         {
  //         
  //            navigate("/Profile");

  //    setIsLoading(false);
  //     }

  //       })
  //       .catch((error) => {
  //        
  //         setIsLoading(false);
  //       });

  //     }
  //    
 

  //   }
  //   //setIsLoading(true);
  //   addToPropImagespath(Imagepath1);

  // };

  const fileinsert = () => {
    setIsLoading(true);
    let filedata = document.querySelector(".ant-upload");

    let remove_error = document.querySelectorAll(".border_red");

    remove_error.forEach((item) => item.classList.remove("border_red"));

    for (let i = 0; i <= fileList?.length; i++) {
      if (fileList[i]?.size > 1902250) {
        filesizeerror = true;
       
        setimagegreaterthan(true);
      }
    }

    if (fileList.length === 0 || fileList.length > 3) {
      setopenerror(true);
      seterrorphotofilesize("");
      seterrorphoto(
        "* Minimum 1 property image needed & maximum 3 images allowed"
      );
      setIsLoading(false);
      let fileuploadeddata = document.querySelector(
        ".ant-upload-list-picture-card-container"
      );
      if (!fileuploadeddata) {
        filedata?.classList.add("border_red");
      }
    } else if (imagegreaterthan === true || filesizeerror === true) {
      setopenerror(true);
      seterrorphoto("");
      seterrorphotofilesize("");
    

      seterrorphoto("* Image should be less than 2 mb");
      setIsLoading(false);
    } else {
      setIsLoading(true);
      addToPropImages(fileList, Imagepath1);
      //localStorage.setItem("Propertyimages", JSON .stringify(fileList));
     

      var form = document.getElementById("formimg");
      const formData = new FormData(form);
      const formData2 = new FormData(form);
      const formData3 = new FormData(form);
      formData.append("file", file || propImages[0]?.originFileObj);

      if (fileList.length > 0) {
        let response = axios
          .post(
            `${onboardapi}/imageupload`,

            formData
          )
          // .post("https://hotelonboardtestapi.staysimplyfied.com/API/imageupload",formData)
          // .post("http://localhost:3000/api/imageupload",formData)
          .then((res) => {
           
            //setImagepath((pre) => [...pre,res.data]);
          
            Imagepath1.push({ name: res.data.filename, path: res.data?.path });
            navigate("/Profile");
            
            if (
              (res.data.message === "Success" && file1 !== "") ||
              propImages[1]?.originFileObj !== ""
            ) {
              setIsLoading(false);
              formData2.append("file", file1 || propImages[1]?.originFileObj);

              let response = axios
                .post(
                  `${onboardapi}/imageupload`,
                  // "http://localhost:3000/api/imageupload",
                  formData2
                )
                // .post("https://hotelonboardtestapi.staysimplyfied.com/API/imageupload",formData2)
                .then((res) => {
                 
                  // setImagepath((pre) => [...pre,res.data]);
                  if (res.data.path !== "" || res.data.path !== undefined) {
                    Imagepath1.push({
                      name: res.data.filename,
                      path: res.data?.path,
                    });
                  }

                  if (
                    (res.data.message === "Success" && file2 !== "") ||
                    propImages[2]?.originFileObj !== ""
                  ) {
                    setIsLoading(false);
                    formData3.append(
                      "file",
                      file2 || propImages[2]?.originFileObj
                    );

                    let response = axios
                      .post(`${onboardapi}/imageupload`, formData3)
                      // .post("https://hotelonboardtestapi.staysimplyfied.com/API/imageupload",formData3)
                      .then((res) => {
                       
                        // setImagepath((pre) => [...pre,res.data]);
                        if (
                          res.data.path !== "" ||
                          res.data.path !== undefined
                        ) {
                          Imagepath1.push({
                            name: res.data.filename,
                            path: res.data?.path,
                          });
                        }
                        setIsLoading(false);
                        navigate("/Profile");
                      })
                      .catch((error) => {
                       
                      });
                  }
                })
                .catch((error) => {
                 
                });
            }
          })
          .catch((error) => {
           
          });
      }

     
    }
    addToPropImagespath(Imagepath1);
   
  };
 

  const handleChangephoto = ({ fileList: newFileList }) => {

    
    let fileextcheck = newFileList.filter(image => (image?.name.split(".")[1] ==='jpg' || image?.name.split(".")[1] ==='jpeg' || image?.name.split(".")[1] ==='png') && image );
    let fileexterrorcheck = newFileList.filter(image => (image?.name.split(".")[1] !=='jpg' && image?.name.split(".")[1] !=='jpeg' && image?.name.split(".")[1] !=='png' ) && image );


   if(fileextcheck?.length >= 0) {

    setimgfilenamelist(fileextcheck);
    setFileList(fileextcheck);
    setpropphoto(fileextcheck);

    setfile(fileextcheck[0]?.originFileObj);
    setfile1(fileextcheck[1]?.originFileObj);
    setfile2(fileextcheck[2]?.originFileObj);
   }
   if(fileexterrorcheck?.length > 0) {
   
    setOpenerrorimg(true);
    
   

    setmessagearrimg([ "* Supported file formats are (.jpg, .jpeg, .png)"]);
   
   }
   
 

    // setfilename(newFileList[0].name)
    let filedata = document.querySelector(".ant-upload");
    let fileuploadeddata = document.querySelector(
      ".ant-upload-list-picture-card-container"
    );
    if (!fileuploadeddata) {
      filedata?.classList.remove("border_red");
    }
    setDataChange("You have unsaved data, do you want to continue?");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    window.scroll(0, 20);
    
    if (propImages) {
      setFileList(propImages);
    }
  }, []);
 
  const okpreviouspage = () => {
    navigate("/roomdet");
  };

  const showprevModal = () => {
  
    if (fileList?.length === 0) {
      setmessagearr([]);
      navigate("/roomdet");
    } else {
     
      if (dataChange !== "") {
        setmessagearr([dataChange]);
        setOpenprevious(true);
      } else {
        navigate("/roomdet");
      }
    }
  };

  const handleCancelerror = () => {
    setOpenprevious(false);
  };
  const handleCancelmodal = () => {
    setOpenerrorimg(false);
  }
  return (
    <div>
      {isLoading && (
        <div className="loaderloading">
          <div className="example">
            <Spin />{" "}
            <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
              Loading ...
            </span>
          </div>
        </div>
      )}
      <nav className="row">
        <a className="navbar-brand col-md-2 text-center mobile-show" href="#">
          <img
            className="3 mt-1"
            src={
              logo_img
            }
            alt=""
            width={"120px"}
          />
        </a>
        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon">fsfds</span>
      </button> */}
        <span className="text-center col-md-12 d-flex justify-content-center align-items-center">
          <h3>
            <b>Property Onboarding</b>
          </h3>
        </span>
      </nav>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-md-2 no-border-l h100"
            style={{ background: "#fff", borderLeft: "none" }}
          >
            <Card className=" col-md-12 h-100 side-menu">
            <Steps
                  size="small"
                  className="mt-md-5 cursor-not-allowed mobile-hide"
                  direction="vertical"
                  current={4}
                >
                  <Steps.Step title={<NavLink to="/">Basics</NavLink>} />
                  
                   <Steps.Step
                    title={<NavLink to="/location">Location</NavLink>}
                  />
                  <Steps.Step
                    title={<NavLink to="/facilities">Facilities</NavLink>}
                  />
                  <Steps.Step
                    title={<NavLink to="/roomdet">Room details</NavLink>}
                  />
                  <Steps.Step title={<NavLink to="/photos">Photos</NavLink>} />
                  <Steps.Step
                    title={<NavLink to="/Profile">Contact</NavLink>}
                  />
                  <Steps.Step
                    title={<NavLink to="/Publish">Publish</NavLink>}
                  />
                </Steps>
              <a
                className="navbar-brand col-md-2 text-center logo-display"
                href="#"
              >
                <img
                  className="logo_name"
                  src={
                    logo_img
                  }
                  alt=""
                />
              </a>
              <p className="mt-sm-3 deskbrowser" style={{ fontSize: "12px" }}>
                <span
                  className="fw-bold"
                  style={{ fontSize: "14px", marginBottom: "10px" }}
                >
                  Supported Browsers :{" "}
                </span>
                <br />{" "}
                <span style={{ margin: "0px", lineHeight: "10px" }}></span>
                1. Google Chrome,
                <br />
                2. Microsoft Edge,
                <br />
                3. Firefox.
              </p>
            </Card>
          </div>
          <div
            className="col-md-10 layout-ml"
            style={{ background: "rgb(227 245 255)" }}
          >
            <div className="row" style={{ marginTop: "10px" }}>
              <div className="col-sm-6 p-3">
                <h3>Show them what they’re missing.</h3>
                <p>
                  Pictures matter to travelers. Upload as maximum three
                  high-quality images as you have.
                </p>
              </div>
              <div className="col-sm-6">
                <form id="formimg" action="#" encType="multipart/form-data">
                  <img src={photoheadpic} alt="" height={"120px"} />
                </form>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-9 photos">
                <h5>
                  Property Images<small> - maximum 3 images </small>
                </h5>
                <Card
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  {/* <h3>Property Photos</h3> */}

                  <div className="row">
                    <Upload
                      // action="https://www.mocky.io/v2/5cc8019d300000980a0f55e76"
                      listType="picture-card"
                      fileList={fileList}
                      accept={".jpg, .jpeg, .png"}
                      // action={"http://localhost:8000/"}
                      name="multifiles"
                      onPreview={handlePreview}
                      onChange={handleChangephoto}
                      multiple={true}
                      // maxCount={3}
                      onRemove={removefile}
                      beforeUpload={(file) => {
                        const isLt2M = file.size / 1024 / 1024 < 2;
                        if (!isLt2M) {
                          // message.error("Image must smaller than 2MB!");
                          setimagegreaterthan(true);
                          return true;
                        } else {
                          setimagegreaterthan(false);
                          return false;
                        }
                      }}
                      className={"prop_upload_error"}
                    >
                      {fileList.length >= 3 ? null : uploadButton}
                    </Upload>
                    <Modal
                      open={previewOpen}
                      title={previewTitle}
                      footer={null}
                      onCancel={handleCancel}
                      maskClosable={false}
                    >
                      <img
                        alt="example"
                        style={{
                          width: "100%",
                        }}
                        src={previewImage}
                      />
                    </Modal>
                  </div>
                </Card>
                <div className="float-end mt-3 mt-sm-3 mb-4">
                  <Button
                    type="primary"
                    style={{ minWidth: "100px", marginRight: "5px" }}
                    onClick={showprevModal}
                    className="dblclick"
                  >
                    Previous
                  </Button>

                  <Button
                    type="primary"
                    className="ms-sm-3 dblclick"
                    style={{ minWidth: "100px" }}
                    onClick={fileinsert}
                  >
                    Save & Continue
                    {/* <NavLink to="/Profile"> </NavLink> */}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Title"
        open={open}
        onCancel={handleCancelbtn}
        maskClosable={false}
      ></Modal>
      <Modal
        className="error_msg"
        title="Error message"
        open={openerror}
        onCancel={handleCancelbtnerror}
        footer={[
          <Button
            key="back"
            type="primary"
            className="dblclick"
            onClick={handleCancelbtnerror}
          >
            Close
          </Button>,
        ]}
        maskClosable={false}
      >
        <p>{errorphoto}</p>
        <p>{errorphotofilesize}</p>
      </Modal>
      <Modal
              className="error_msg"
              title="Error message"
              open={Openerorimg}
              onCancel={handleCancelmodal}
              footer={[
                <Button
                  key="back"
                  type="primary"
                  className="dblclick"
                  onClick={handleCancelmodal}
                >
                  Close
                </Button>,
              ]}
              maskClosable={false}
            >
              {messagearrimg &&
                messagearrimg.map((item, index) => <p key={index}>{item}</p>)}
            </Modal>
      <Modal
        className="error_msg"
        title="Error message"
        open={openprevious}
        onCancel={handleCancelerror}
        footer={[
          <Button
            key="back"
            type="primary"
            className="dblclick"
            onClick={okpreviouspage}
          >
            Ok
          </Button>,
          <Button
            key="back1"
            type="primary"
            className="dblclick"
            onClick={handleCancelerror}
          >
            Cancel
          </Button>,
        ]}
        maskClosable={false}
      >
        * You have unsaved data, do you want to continue?
      </Modal>
    </div>
  );
}
