import React, { useEffect, useState,useContext } from "react";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { PropertyContext } from "../../HB Components/propertyContext/PropertyContext";
const GoogleMapComponent = (props) => {
    const {latlong} = useContext(PropertyContext);
    const [lt,setlt]=useState({
        lat:  28,
        lng:  77
    })
    if(lt!==latlong)
    {
        setlt(latlong)
    }
    let markersList = [
        { lat: 28, lng: 77 },
       
    ]
    let [markers, setMarkers] = useState(markersList);
    let onMarkerDragEnd = (coord, index, markers) => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();
        markers[index] = { lat, lng };
        props.receivelatlong({ lat, lng })
        setMarkers(markers);
    }
    let myMarkers = markers && Object.entries(markers).map(([key, val]) => (
        <Marker key={key} id={key} position={!isNaN(props?.latlngdet.lat) && props?.latlngdet ||{
            lat: val.lat,
            lng: val.lng
        }} 
            // onClick={() => console.log("Clicked")}
            draggable={true}
            onDragend={(t, map, coord) => onMarkerDragEnd(coord, key, markers)}
        />
    ))

    
    return (
        <>
            <div>
                 <div className="row d-flex justify-content-center text-center maplatlong">
                    
                    <Map className="maplatlong_map"
                       
                        google={props?.google}
                        zoom={lt?.lat !== 28  ? 15 : 6}
                        initialCenter={
                           lt
                         }
                     
                        center={
                            props?.latlngdet.lat && props?.latlngdet||lt
                        } 
                    >
                        {myMarkers}
                        </Map>
                        
           
                </div>
            </div>
        </>
    );
}
export default GoogleApiWrapper({
    apiKey: 'AIzaSyA64hWNaMkhU56Bz0nix2TOzJJf2DoqT0A',
    region: 'IN'
})(GoogleMapComponent);