import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Facilities from "../Components/Facilities";
import Location from "../Components/Location";
import Photos from "../Components/Photos";

import Profile from "../Components/Profile";

import Propcreation from "../Components/Propcreation";

import Publish from "../Components/Publish";
import Roomdet from "../Components/Roomdet";

import { useState } from "react";

import App from "../../../App";




export default function MainRout() {
  const [data1,setData1]=useState([])
  const [propname,setpropname] =useState("")
  const [gstnum,setgstnum]=useState("")



   
  return (
    <BrowserRouter>
     <Routes>
   
   
      <Route path={"/location"} element={<Location />}>
        </Route>
        <Route path={"/location/:id"} element={<Location />}>
        </Route>
      <Route path={"/"}  element={<Propcreation setpropname={setpropname} setgstnum={setgstnum} />}>
      <Route path={"/:id"}  element={<Propcreation setpropname={setpropname} setgstnum={setgstnum} />}></Route>
        </Route>
        <Route path={"/roomdet"} element={<Roomdet />}>
        </Route>
        <Route path={"/photos"} element={<Photos />}>
        </Route>
        <Route path={"/facilities"} element={<Facilities/>}>
        </Route>
        {/* <Route path={"/Places"} element={<Places />}>
        </Route> */}
        <Route path={"/Profile"} element={<Profile setData1={setData1} />}>
        </Route>
        <Route path={"/Publish"} element={<Publish data1={data1} propname={propname} gstnum={gstnum}/>}>
        </Route>
    
        <Route path={"/mapload"} element={<App />}>

        </Route>
       
       
      </Routes>
  </BrowserRouter>
  )
}
