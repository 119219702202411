import GoogleMapComponent from "./Property/Property Management/Components/GoogleMapComponent";

function App() {
  return (
    <div className="App">
      
{/* 
       <div className="text-center propertyclasstitle ">
         Property Management
      </div> */}

<GoogleMapComponent />
      

    </div>
  );
}

export default App;
