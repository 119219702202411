import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';

import MainRout from './Property/Property Management/Routes/MainRout';
import { PropertyProvider } from './Property/HB Components/propertyContext/PropertyContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <PropertyProvider>
    <MainRout />
    </PropertyProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
