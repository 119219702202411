import React,{createContext,useReducer} from 'react'
import PropertyReducer from './PropertyReducer';


const initialState = {
  locationValue:[],
   facilities:[],
   roomDetails:[],
   propImages:[],
   profileDetails:[],
  basic:[],
  paymentVerified:true,
  upiverified:true,
  basicverify:false,
  profileacverified:false,
  profileupiverified:false,
  upi_name:{},
  bankac_name:{},
 gstvarified_details:[],
 propImagespath:[],
 profileImagePath:[],
 latlong:{ lat: 28, lng: 77 },
 ch_managerid:0,
 onboardapi:'https://onboardapi.hummingbirdindia.com/API',
 //onboardapi:
 //'http://localhost:3000/api', 
 //'https://hotelonboardtestapi.staysimplyfied.com/API',
 logo_img:"https://portalvhds4prl9ymlwxnt8.blob.core.windows.net/img/HBD_logo.png",
 //"https://endpoint887127.azureedge.net/pimages/OrTFH2wg_newhblogo_250x150.png",
  }

  //'https://hotelonboardtestapi.staysimplyfied.com/API'
//basic,locationValue,facilities,roomDetails,propImages,profileDetails

export const PropertyContext = createContext(initialState);

export const PropertyProvider = (props) => {

    const [state,dispatch] = useReducer(PropertyReducer,initialState);


  const addToBasic = (values) => {
    dispatch({ type: "ADD_TO_Basic", payload: values });

  };

   const addToLocations = (values) => {
    dispatch({ type: "ADD_TO_LOCATIONS", payload: values });
  };

  const addToFacilities = (values) => {
    dispatch({ type: "ADD_TO_FACILITIES", payload: values });
  };
  const addToRoomDetails = (values) => {
    dispatch({ type: "ADD_TO_ROOMDETAILS", payload: values });
  };
  const addToPropImages = (values) => {
    dispatch({ type: "ADD_TO_PROPIMAGES", payload: values });
  };
  const addToPropImagespath = (values) => {
    dispatch({ type: "ADD_TO_PROPIMAGES_PATH", payload: values });
  };
  const addToProfile = (values) => {
    dispatch({ type: "ADD_TO_Profile", payload: values });
  };
  const payment_verified = (values) => {
    dispatch({ type: "ADD_TO_PAYMENT_VERIFIED", payload: values });
    // console.log(values)
  };
  const upi_verified = (values) => {
    dispatch({ type: "ADD_TO_UPI_VERIFIED", payload: values });
    // console.log(values)
  };
  //verifybut
  const basic_Verifiedbut = (values) => {
    dispatch({ type: "BASIC_VERIFIED", payload: values });
  };
  const profile_acVerifiedbut = (values) => {
    dispatch({ type: "PROFILE_AC_VERIFIED", payload: values });
  };
  const profile_upi_Verifiedbut = (values) => {
    dispatch({ type: "PROFILE_UPI_VERIFIED", payload: values });
  };
  const upiNamefunc = (values) => {
    dispatch({ type: "VERIFIED_UPI_DETAILS", payload: values });
  };
  const bankNamefunc = (values) => {
    dispatch({ type: "VERIFIED_Bank_DETAILS", payload: values });
  };
  const basic_GST_verified = (values) => {
    dispatch({ type: "VERIFIED_GST_DETAILS", payload: values });
  };
  const profileImagePathfunc = (values) => {
    dispatch({ type: "PROFILE_IMAGE_DETAILS", payload: values });
  };
  const getchannelmanegerid = (values) => {
    dispatch({ type: "GET_CHANNEL_ID", payload: values });
    // console.log(values)
  };

  //Enter number only
  const textNum = (e) => {
    return e.target.value.replace(/\D/g,'')
    }
    const decimalinput = (e) => {
    const IsNumericval = (e) => /^-{0,1}\d*\.{0,1}\d+$/.test(e);
    if(IsNumericval) {
      return true;
    }
    else {
      return false;
    }
    }
    //remove space
  const propInput = (e) => {
    if(/^\s/g.test(e.target.value)){
    e.target.value = e.target.value.replace(/^\s/g, "");
    
     }
     else {
      e.target.value = e.target.value.replace(/^[ \t]+|[ \t]+$/gm, " ");
     }
    }
    const propInput1 = (e) => {
      if(/^\s/g.test(e.target.value)){
      e.target.value = e.target.value?.replace(/^\s/g, "");
      
       }
       else {
        e.target.value = e.target.value?.replace(/^[ \t]+|[ \t]+$/gm, "");
       }
      }
//multi space trim
const multispacetrim = (value) => { 
  return value?.replace(/  /g,' ').trim()
  }
  //lat long
const getLatLongfunc = (values) => {
  dispatch({ type: "GET_LAT_LONG", payload: values });
  }

    return (
        <PropertyContext.Provider 
        value = {{
            basic:state.basic,
            addToBasic,
            locationValue:state.locationValue,
            addToLocations,
            facilities:state.facilities,
            addToFacilities,
            roomDetails:state.roomDetails,
            addToRoomDetails,
            propImages:state.propImages,
            addToPropImages,
            profileDetails:state.profileDetails,
            addToProfile,
            paymentVerified:state.paymentVerified,
            payment_verified,
            upiverified:state.upiverified, 
            upi_verified,
            basicverify:state.basicverify,
            basic_Verifiedbut,
            profileacverified:state.profileacverified,
            profile_acVerifiedbut,
            profileupiverified:state.profileupiverified,
            profile_upi_Verifiedbut,
            upi_name:state.upi_name,
            upiNamefunc,
            bankac_name:state.bankac_name,
            bankNamefunc,
            gstvarified_details:state.gstvarified_details,
            basic_GST_verified,
            propImagespath:state.propImagespath,
            addToPropImagespath,
            profileImagePath:state.profileImagePath,
            profileImagePathfunc,
            textNum,
            propInput,
            multispacetrim,
            latlong:state.latlong,
            getLatLongfunc,
            decimalinput,
            ch_managerid:state.ch_managerid,
            getchannelmanegerid,
            propInput1,
            onboardapi:state.onboardapi,
            logo_img:state.logo_img
        }}>{props.children}</PropertyContext.Provider>
    )
}

