export default (state,action) => {
    switch(action.type){
        case "ADD_TO_Basic":
            return {
              ...state,
              basic:action.payload,
            };
            case "ADD_TO_LOCATIONS":
            return {
              ...state,
              locationValue: action.payload,
              
            };
            case "ADD_TO_FACILITIES":
            return {
              ...state,
              facilities: action.payload,
              
            };
            case "ADD_TO_ROOMDETAILS":
                return {
                  ...state,
                  roomDetails: action.payload,
                  
                };
                case "ADD_TO_PROPIMAGES":
                return {
                  ...state,
                  propImages: action.payload,
                  
                };
                case "ADD_TO_PROPIMAGES_PATH":
                return {
                  ...state,
                  propImagespath: action.payload,
                  
                };
                case "ADD_TO_Profile":
                return {
                  ...state,
                  profileDetails: action.payload,
                  
                };
                case "ADD_TO_PAYMENT_VERIFIED":
                return {
                  ...state,
                  paymentVerified: action.payload,
                  
                };
                case "ADD_TO_UPI_VERIFIED":
                return {
                  ...state,
                  upiverified: action.payload,
                  
                };
                case "BASIC_VERIFIED":
                return {
                  ...state,
                  basicverify: action.payload,
                  
                };
                case "PROFILE_AC_VERIFIED":
                return {
                  ...state,
                  profileacverified: action.payload,
                  
                };
                case "PROFILE_UPI_VERIFIED":
                return {
                  ...state,
                  profileupiverified: action.payload,
                  
                };
                case "VERIFIED_UPI_DETAILS":
                return {
                  ...state,
                  upi_name: action.payload,
                  
                };
                case "VERIFIED_Bank_DETAILS":
                return {
                  ...state,
                  bankac_name: action.payload,
                  
                };
                case "VERIFIED_GST_DETAILS":
                return {
                  ...state,
                  gstvarified_details: action.payload,
                  
                };
                case "PROFILE_IMAGE_DETAILS":
                return {
                  ...state,
                  profileImagePath: action.payload,
                  
                };
                case "GET_LAT_LONG":
                return {
                  ...state,
                  latlong: action.payload,
                  
                };
                case "GET_CHANNEL_ID":
                return {
                  ...state,
                  ch_managerid: action.payload,
                  
                };
                
            default:
                return state;
    }
}