import { useState, useEffect, useRef, useContext, debounce } from "react";
import { Button, AutoComplete } from "antd";
import { Card } from "antd";
import "../styles/propinfo.css";
import "antd/dist/antd.css";
import { Input } from "antd";
import { Radio } from "antd";
import { TimePicker } from "antd";
import { Steps, items } from "antd";
import { Rate } from "antd";

// import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Modal, message } from "antd";
import { useNavigate } from "react-router-dom";
import bagpic from "../assets/welcome.png";
import React from "react";
import axios from "axios";
import { Select, Checkbox } from "antd";
import { Spin } from "antd";
import "../styles/propinfo.css";
import { CheckOutlined } from "@ant-design/icons";
import { PropertyContext } from "../../HB Components/propertyContext/PropertyContext";

export default function Propcreation() {
  const {
    basic,
    addToBasic,
    basicverify,
    basic_Verifiedbut,
    basic_GST_verified,
    gstvarified_details,
    propInput,
    multispacetrim,
    ch_managerid,
    getchannelmanegerid,
    addToLocations,
    locationValue,
    onboardapi,
    logo_img
  } = useContext(PropertyContext);
 

  const { TextArea } = Input;

  const [checkintime, setcheckintime] = useState(basic[0]?.checkintime || "12");
  const [chktimeupdt, setchktimeupdt] = useState("");
  const [chkouttimeupdt, setchkouttimeupdt] = useState("");
  const [checkintype, setcheckintype] = useState(basic[0]?.checkintype || "AM");
  const [errorchkintime, seterrorchkintime] = useState("");
  const [checkouttime, setcheckoutime] = useState(
    basic[0]?.checkouttime || "12"
  );
  const [errorchkouttime, seterrorchkouttime] = useState("");
  const [checkouttype, setcheckouttype] = useState(
    basic[0]?.checkouttype || "AM"
  );
  const [gstnumber, setgstnumber] = useState(basic[0]?.gstnumber);
  const [propdescription, setpropdescription] = useState(
    basic[0]?.propdescription
  );
  const [cancellationpolicy, setcancellationpolicy] = useState("");
  const [starrating, setstarrating] = useState("");
  const [propertyname, setpropertyname] = useState(basic[0]?.propertyname);
  const [propertylocalname, setpropertylocalname] = useState("");
  const [propertytype, setpropertytype] = useState(basic[0]?.propertytype || 0);
  const [Show, setShow] = useState(true);
  const [showserapart, setshowserapart] = useState(false);
  const [websiteAddress, setwebsiteAddress] = useState(
    basic[0]?.websiteAddress
  );
  const [legalname, setlegalname] = useState(basic[0]?.legalname);
  const [messageApi, contextHolder] = message.useMessage();
  const [open, setOpen] = useState(false);
  const [showchnlmanger, setshowchnlmanger] = useState(false);
  const [ctime, setctime] = useState();
  const [Channelmanager, setChannelmanager] = useState(
    basic[0]?.ChannelManager !== "" ? basic[0]?.ChannelManager : ""
  );
  const [ChannelManagerload, setChannelManagerload] = useState([]);
  const [shwchkinouttime, setshwchkinouttime] = useState(true);
  const [Policies1, setPolicies1] = useState(basic[0]?.Policies1 || "1 day");
  const [Policies2, setPolicies2] = useState(
    basic[0]?.Policies2 || "the room night"
  );
  const [cancelpolicy1, setcancelpolicy1] = useState(
    basic[0]?.cancelpolicy1 || "1 day"
  );
  const [cancelpolicy2, setcancelpolicy2] = useState(
    basic[0]?.cancelpolicy2 || "the room night"
  );
  const [showverifysymb, setshowverifysymb] = useState(false);
  const [showverifybtn, setshowverifybtn] = useState(true);
  const [errorwebsiteAddress, seterrorwebsiteaddress] = useState("");
  const [gststatus, setgststatus] = useState("");
  const [oneday, setOneday] = useState("");
  const [disablechktime, setdisablechktime] = useState(
    (basic[0]?.oneday || (oneday && oneday)) === "24 hrs" ? true : false
  );
  const [propertytypeId, setpropertytypeId] = useState("");
  const [proptertytypeload, setpropertytypeload] = useState([]);
  const [propertytypeoption, setpropertytypeoption] = useState(
    basic[0]?.propertytypeoption || "Hotel"
  );
  const [channelmanageroption, setchannnelmanageroption] = useState(
    basic[0]?.channelmanageroption || "No"
  );
  const [otherstext, setotherstext] = useState(basic[0]?.otherstext);
  const [serviceApp, setServiceApp] = useState("");
 
  const [errormessagearr, setmessagearr] = useState([]);
  const propnameRef = useRef(null);
  const propdescref = useRef(null);
  const websiteaddref = useRef(null);
  const [opengsterror, setopengsterror] = useState(false);
  const [gsterrofmsg, setgsterrofmsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validwebsite, setvalidwebsite] = useState(true);
  const [chnlmanagerid, setchnlmanagerïd] = useState("");
  const [tradename, settradename] = useState("");
  const [cancelpolicyload1, setcancelpolicyload1] = useState([]);
  const [cancelpolicyload2, setcancelpolicyload2] = useState([]);
  const [otherchnlmanagerid, setotherchnlmanagerid] = useState("");
  let websiteerror = true;
  var propertypeset = 0;
  // let channelmanagerothersload=[];
  const { confirm } = Modal;
  const [textBox, setTextBox] = useState(false);

  let newchannelmanager;
  let errorchannelmanager = false;

  let numincrement = 1;

  const onChangecheckin = (time, timeString) => {
    setchktimeupdt(time);

    setctime(time);
  };
  const othersoption = { value: "Others", label: "Others" };

  function myFunction(e) {
    e.preventDefault();
  }

  const onChangecheckout = (time, timeString) => {
    
    setchkouttimeupdt(time);
    const aftercolonSlash = timeString.split(":").pop();
    const beforeslash = timeString.split(":");

    setcheckoutime(aftercolonSlash);
    setcheckouttype(beforeslash);
  };
  const [value, setValue] = useState(1);
  const onChangeradio = (e) => {
    setpropertytype(0);
    // setValue(e.target.value);
    setpropertytypeoption(e.target.value);
    setShow(false);
    if (e.target.value == "Hotel") {
      setShow(true);
      setshowserapart(false);
    }
    if (e.target.value === "Service Apartment") {
      setShow(false);
      setshowserapart(true);
    }
    if (e.target.value === "PG/Hostel") {
      setShow(false);
      setshowserapart(false);
      setpropertytype("15");
    }
    if (e.target.value === "Resort") {
      setShow(false);
      setshowserapart(false);
      setpropertytype("16");
    }
  };

  const onChangeradiochnlmanager = (e) => {
   

    setchannnelmanageroption(e.target.value);
    getchannelmanegerid(0);
    if (e.target.value == "Yes") {
      setshowchnlmanger(true);
      setotherstext("");
    

 
    }
    if (e.target.value == "No") {
     
      setChannelmanager("");
      setshowchnlmanger(false);
      setTextBox(false);
      setotherstext("");
      setchnlmanagerïd("");
    }
  };

  const [starvalue, setstarvalue] = useState();

  const navigate = useNavigate();
  const dbclick = (event) => {

    event.preventDefault();
  };
  const showModal = (e) => {
 

    setmessagearr([]);

    let prop_Name = document.querySelector("#prop_Name");
    let prop_type = document.querySelector("#prop_type");
    let check_time = document.querySelector("#check_time");
    let checkout_time = document.querySelector("#checkout_time");
    let cheoneday = document.querySelector("#cheoneday");
    let pro_descrip = document.querySelector("#pro_descrip");
    let gst_number = document.querySelector("#gst_number");
    let c_manager_group = document.querySelector("#c_manager_group");
    let channel_manager_text = document.querySelector(".channel_manager_text");
    let web_site = document.querySelector("#web_site");
    let setcancelation = document.querySelector(".setcancelation");
    let remove_error = document.querySelectorAll(".border_red");
    let channel_manager_select = document.querySelector(
      ".channel_manager_select > div > div > span > input"
    );

    remove_error.forEach((item) => item.classList.remove("border_red"));

    // function isValidURL(websiteAddress) {
    //   var res = websiteAddress.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    //   return (res !== null)
    // };
    // isValidURL(websiteAddress)
    // if(propertyname==="" || basic[0]?.propertyname)
    if (prop_Name?.value === "") {
      // setOpen(true)
      setmessagearr((pre) => [...pre, "* Please enter the property name"]);
      prop_Name.classList.add("border_red");
    }

    if (prop_type?.value === "") {
      setmessagearr((pre) => [...pre, "* Please enter the property type"]);
      prop_type.classList.add("border_red");
    }
    if (cheoneday.checked === false) {
      if (check_time?.value === "" || checkout_time?.value === "") {
        let date_in = document.querySelector(".date_in > div");

        setmessagearr((pre) => [
          ...pre,
          "* Please enter the check-in & check-out time",
        ]);
        if (check_time?.value === "") {
          date_in.classList.add("border_red");
        }
        if (checkout_time?.value === "") {
          let date_out = document.querySelector(".date_out > div");
          date_out.classList.add("border_red");
        }
      }
    }
    //  if(chktimeupdt!=="" || oneday==="")
    // if(cheoneday?.value ==="")
    // {
    //  // setOpen(true)

    //   setmessagearr((pre) => [...pre,"* Please enter the Check-In Time"]);
    // }
    // if(chkouttimeupdt!=="" || oneday==="")

    if (propertytypeoption && propertytypeoption === "Service Apartment") {
     
      let select_ser_app = document.querySelector(
        ".service-apt .ant-card .ant-card-body .ant-select .ant-select-selector .ant-select-selection-placeholder"
      );
   
      if (select_ser_app?.innerHTML === "Select Service Apartment") {
        setmessagearr((pre) => [...pre, "* Please select service apartment"]);
        select_ser_app?.classList.add("border_red");
      }
    }
    if (pro_descrip?.value === "") {
      setmessagearr((pre) => [
        ...pre,
        "* Please enter the property description",
      ]);
      pro_descrip.classList.add("border_red");
    }
    if (gst_number?.value === "") {
      setmessagearr((pre) => [...pre, `* Please enter the GSTIN`]);
      gst_number.classList.add("border_red");
    } else {
      if (basicverify === false) {
        setmessagearr((pre) => [...pre, "* Please verify the GSTIN"]);
      }
    }
    if (propertytypeoption === "Hotel") {
      if (propertytype < 1) {
        setmessagearr((pre) => [...pre, "* Please give your home a rating"]);
      }

      pro_descrip.classList.add("border_red");
    }

    // if (
    //   channelmanageroption === "Yes" ||
    //   basic[0]?.channelmanageroption === "Yes"
    // ) {

    //   if (Channelmanager === "" || Channelmanager === undefined) {
    //       // let channel_manager_select = document.querySelector(
    //       //   ".channel_manager_select > div > div"
    //       // );

    //         setmessagearr((pre) => [
    //           ...pre,
    //           "* Please select the channel manager",
    //         ]);
    //         // channel_manager_select.classList.add("border_red");

    //     }
    //   else if (Channelmanager === "Others") {
    //     let channel_manager_text = document.querySelector(
    //       ".channel_manager_text"
    //     );
    //     if (channel_manager_text.value === "") {
    //      
    //       setmessagearr((pre) => [
    //         ...pre,
    //         "* Please enter the channel manager",
    //       ]);
    //     }
    //   }
    // } else {
    // }

    let chanl_mng = document.querySelector("#c_manager_group .ant-radio-checked input")?.value;
    if (chanl_mng === "Yes") {
      let channel_manager_select = document.querySelector(
        ".channel_manager_select > div > div span input"
      );
      
      if (
        channel_manager_select?.value === "" ||
        channel_manager_select?.value === undefined
      ) {
     

        setmessagearr((pre) => [...pre, "* Please select the channel manager"]);
        channel_manager_select.classList.add("border_red");
      } else if (channel_manager_select?.value === "Others") {
        let channel_manager_text = document.querySelector(
          ".channel_manager_text"
        );
        errorchannelmanager = false;
        if (channel_manager_text.value === "") {
          setmessagearr((pre) => [
            ...pre,
            "* Please enter the channel manager",
          ]);
          channel_manager_text.classList.add("border_red");
        }
      }
    } else {
      errorchannelmanager = false;
    }

    let channelmanaer = document.querySelector(
      ".ant-select-selection-placeholder"
    );

    // if (channel_manager_text?.value === "") {

    //   setmessagearr((pre) => [
    //     ...pre,
    //     "* Please enter the channel manager",
    //   ]);
    //   channel_manager_text.classList.add("border_red");
    // }

    if (web_site?.value !== "") {
      function isValidURL(string) {
        var res = string.match(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
        );
        return res !== null;
      }

      websiteerror = isValidURL(web_site?.value);
      if (websiteerror !== true) {
        if(propertyname===""||propertyname === undefined){
          setmessagearr((pre) => [
            ...pre,
            "* Please enter a valid website address",
            `(e.g. https://www.example.com)`,
          ]);
        }else{
          setmessagearr((pre) => [
            ...pre,
            "* Please enter a valid website address",
            `(e.g. https://www.${propertyname?.replace(/\s+/g, '')}.com)`,
          ]);
        }
        
      
        
        
      }
    }
    if (channel_manager_select?.value !== '' && channel_manager_select?.value  !== "Others" && chanl_mng === "Yes") {
      let check_channelvalue = ChannelManagerload?.filter((item) => item?.Channelmanager.toLowerCase() === channel_manager_select?.value.toLowerCase())
      if(check_channelvalue?.length === 0){
        setmessagearr((pre) => [
          ...pre,
          "* Please select the channel manager from the list",
        ]);

       // seterrorchannelmanager(true);
        errorchannelmanager = true;

      }

    }
    if (
      cheoneday.checked === false
        ? prop_Name?.value === "" ||
          prop_type?.value === "" ||
          check_time?.value === "" ||
          checkout_time?.value === "" ||
          pro_descrip?.value === "" ||
          gst_number?.value === "" ||
          c_manager_group?.value === "" ||
          channel_manager_text?.value === "" ||
          (channel_manager_select && channel_manager_select === "") ||
          (channel_manager_select && channel_manager_select === null) ||
          channelmanaer?.innerHTML === "Select Channel Manager" ||
          basicverify === false ||
          validwebsite === false ||
          (propertytypeoption === "Hotel" && propertytype < 1) ||
          (propertytypeoption === "Service Apartment" && propertytype < 1) ||
          websiteerror === false ||
          errorchannelmanager===true
        : prop_Name?.value === "" ||
          prop_type?.value === "" ||
          pro_descrip?.value === "" ||
          gst_number?.value === "" ||
          c_manager_group?.value === "" ||
          channel_manager_text?.value === "" ||
          (channel_manager_select && channel_manager_select === "") ||
          (channel_manager_select && channel_manager_select === null) ||
          channelmanaer?.innerHTML === "Select Channel Manager" ||
          basicverify === false ||
          validwebsite === false ||
          (propertytypeoption === "Hotel" && propertytype < 1) ||
          (propertytypeoption === "Service Apartment" && propertytype < 1) ||
          websiteerror === false ||
          errorchannelmanager===true
    ) {
      setOpen(true);
    } else {
      // setIsLoading(true);

      let proparr = [
        {
          propertyname: multispacetrim(propertyname) || basic[0]?.propertyname,
          propertytype: propertytype || basic[0]?.propertytype,
          propertytypeoption:
            propertytypeoption || basic[0]?.propertytypeoption,
          checkintime: checkintime || basic[0]?.checkintime,
          //checkintype: checkintype || basic[0]?.checkintype,
          checkouttime: checkouttime || basic[0]?.checkouttime,
          // checkintype: chktimeupdt || basic[0]?.checkintype,
          checkouttype: chkouttimeupdt || basic[0]?.checkouttype,
          gstnumber: gstnumber || basic[0]?.gstnumber,
          propdescription:
            multispacetrim(propdescription) || basic[0]?.propdescription,
          cancellationpolicy: setcancelation?.innerHTML,
          websiteAddress:
            multispacetrim(websiteAddress) || basic[0]?.websiteAddress,
          Channelmanager:
            channelmanageroption === "No"
              ? ""
              : Channelmanager || basic[0].Channelmanager,
          gststatus: gststatus || basic[0]?.gststatus,
          legalname: legalname || basic[0]?.legalname,
          channelmanageroption:
            channelmanageroption || basic[0]?.channelmanageroption,
          otherstext: multispacetrim(otherstext) || basic[0]?.otherstext,
          Policies1: Policies1 || basic[0]?.Policies1,
          Policies2: Policies2 || basic[0]?.Policies2,
          oneday: oneday || basic[0]?.oneday,
          serviceApp: serviceApp || basic[0]?.serviceApp,
          checkouttypeampm: checkouttype || basic[0]?.checkouttype,
          checkintypeampm: checkintype || basic[0]?.checkintype,
          chnlmanagerid:
            channelmanageroption === "No"
              ? ""
              : chnlmanagerid || basic[0]?.chnlmanagerid,
          cancelpolicy1: cancelpolicy1 || basic[0]?.cancelpolicy1,
          cancelpolicy2: cancelpolicy2 || basic[0]?.cancelpolicy2,
          basic_Verifiedbut: basic_Verifiedbut,
          tradename: tradename || basic[0]?.tradename,
          otherchnlmanagerid: ch_managerid || basic[0]?.otherchnlmanagerid,
          // propertypeset:propertypeset||basic[0].propertypeset
        },
      ];
     
      //localStorage.setItem("Propbasics", JSON.stringify(proparr));
      addToBasic(proparr);
     

      if (
        Channelmanager === "Others" ||
        basic[0]?.Channelmanager === "Others"
      ) {
        let data = {
          Channelmanager: otherstext,
        };
        const responsepropertytype = axios
          .post(`${onboardapi}/Insertchannelmanager`, data)
          // .post(
          //   "https://hotelonboardtestapi.staysimplyfied.com/API/Insertchannelmanager",
          //   data
          // )

          // .post("http://localhost:3000/api/Insertchannelmanager", data)
          .then(async (res) => {
           
            //  setmessagearr([]);
            setIsLoading(false);
            if (res.data[0]?.msg === "ALREADY EXISTS") {
              
              setOpen(true);
              setmessagearr((pre) => [
                ...pre,
                "Channel manager already exists",
              ]);
              setIsLoading(false);
            } else {
            
              newchannelmanager = res.data[0]?.Id;
              //  setotherchnlmanagerid(res?.data[0]?.Id)
              let chnlid = [res.data[0]?.Id].filter(
                (element) => element !== undefined
              );
              getchannelmanegerid(res.data[0]?.Id);
           
              setchnlmanagerïd(chnlid[0]);
              setChannelmanager(otherstext);
              setIsLoading(false);
              getchannelmanegerid(res.data[0]?.Id);
              // setIsLoading(true);
              return navigate("/location");
            }

            // setpropertytypeId(res.data[0].Id);
          });
       
      }

      return navigate("/location");
    }
    // }
  };

 
  let options = [];

  useEffect(() => {
    // window.close(``, `_parent`, ``);
    // window.removeEventListener('beforeunload', handleTabClose);
    window.scrollTo(0, 0);
    // clear the time when 24 hrs selected
    

    if ((basic[0]?.oneday || (oneday && oneday)) === "24 hrs") {
      setcheckintime("");
      setcheckintype("");
      setcheckouttype("");
      setcheckoutime("");
    }

    
    setcancellationpolicy(
      `The guest must cancel by ${
        cancelpolicy1 !== "1 day" ? cancelpolicy1 : "1 day"
      } on the day of arrival or pay 100% of the price  ${
        cancelpolicy2 !== "" ? cancelpolicy2 : "the room night"
      }`
    );
    const items = JSON.parse(localStorage.getItem("Propbasics"));
    if (items) {
      setpropertyname(items[0].propertyname);
    }

    const responsepropertytype = axios
      .post(`${onboardapi}/Propertytype`)
      // .post("https://hotelonboardtestapi.staysimplyfied.com/API/Propertytype")

      .then((res) => {
        setpropertytypeload(res.data);
      })
      .catch((err) => {
       
      });
    const responsechannelmanger = axios
      // .post("http://localhost:3000/api/Selectchannelmanager")
      .post(`${onboardapi}/Selectchannelmanager`)
      // .post(
      //   "https://hotelonboardtestapi.staysimplyfied.com/API/Selectchannelmanager"
      // )

      .then((res) => {
        setChannelManagerload(res.data);
       
        // setchannelmanagerothersload(ChannelManagerload.splice(res.data[80],1))
        // channelmanagerothersload.push({Channelmanager:"Others"})
        // ChannelManagerload.push({Channelmanager:"Others"})

   
      })
      .catch((err) => {
       
      });

   
    // //set default property type

    if (basic[0]?.propertytypeoption) {
      if (basic[0]?.propertytypeoption === "Hotel") {
        setShow(true);
      } else {
        setShow(false);
      }
    }

    axios
      .post(`${onboardapi}/selectcancelpolicy`)
      // .post(
      //   "https://hotelonboardtestapi.staysimplyfied.com/API/selectcancelpolicy"
      // )

      //.post("http://localhost:3000/api/selectcancelpolicy")
      .then((res) => {
        setcancelpolicyload1(res.data[0]);
        setcancelpolicyload2(res.data[1]);
      })
      .catch((err) => {
       
      });

    if (gstnumber != "") {
      const body = {
        GSTNo: gstnumber,
      };

      const response = axios
        .post(`${onboardapi}/GSTNoValidatetoken`,
       
          //"https://warsoftapi.warsoft.in/API/GSTNoValidatetoken/Validate",
          body
        )
        .then((res) => {
         
          axios
            .post(`${onboardapi}/selectstate`)
            // .post(
            //   "https://hotelonboardtestapi.staysimplyfied.com/API/selectstate"
            // )

            .then((resstate) => {
              let state_id = resstate.data.data.filter(
                (item) =>
                  item.StateName ===
                  res.data?.MESSAGE?.result?.pradr?.addr?.stcd
              );

              addToLocations([
                {
                  ...locationValue[0],
                  Statename: res.data?.MESSAGE?.result?.pradr?.addr?.stcd,
                  StateId: state_id[0]?.Id,
                },
              ]);
            })
            .catch((err) => {});
        });
    }
  }, []);

  const onChangechecktime = (e) => {
    
    if (e.target.checked == true) {
      setdisablechktime(true);
      setcheckintime(" ");
      setcheckintype(" ");
      setcheckouttype(" ");
      setcheckoutime(" ");
      setchktimeupdt("");
      setchkouttimeupdt("");
      // new value asigned
      setOneday("24 hrs");
      
    } else {
      setdisablechktime(false);
      setOneday("0 hrs");
    }
    if (e.target.checked == false) {
      setdisablechktime(false);
      setcheckintime("12");
      setcheckintype("AM");
      setcheckouttype("AM");
      setcheckoutime("12");
    } else {
    }
  };

  // function gstnumverification() {

  //   if (gstnumber === "" || gstnumber === undefined) {
  //     setopengsterror(true);
  //     setmessagearr(["* Please enter the GSTIN"]);
  //     setOpen(true);
  //   } else {
  //     if (gstnumber?.length < 15) {
  //       setmessagearr(["* GSTIN must be 15 digits"]);
  //       setOpen(true);
  //     } else {
  //       setIsLoading(true);
  //     }
  //   }

  //   const body = {
  //     GSTNo: gstnumber,
  //   };
  //   const response = axios
  //     .post(
  //       "https://warsoftapi.warsoft.in/API/GSTNoValidatetoken/Validate",
  //       body
  //     )
  //     .then((res) => {
  //    

  //       if (res.data.MESSAGE.success === true) {
  //         setgststatus(res.data.MESSAGE.result.sts);
  //         setlegalname(res.data.MESSAGE.result.lgnm);
  //         basic_GST_verified(res.data.MESSAGE.result)
  //         // setshowverifysymb(true);
  //         // setshowverifybtn(false);"
  //         settradename(res.data.MESSAGE.result.tradeNam)
  //         basic_Verifiedbut(true);
  //         setgsterrofmsg(false);

  //       } else {
  //         setopengsterror(true);
  //         setgsterrofmsg(true);
  //         if(gstnumber?.length < 15){
  //           setmessagearr(["* GST number must be 15 digits"]);
  //         }else {
  //         setmessagearr(["* Invalid GST number","* It is your responsibility to review local laws and taxes. Be responsibile in paying any applicable taxes (GST) on all bookings."]);

  //           }
  //             setOpen(true);
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //      
  //       setIsLoading(false);

  //     });
  //  
  // }

  function gstnumverification() {
    if (gstnumber === "" || gstnumber === undefined) {
      setopengsterror(true);
      setmessagearr(["* Please enter the GSTIN"]);
      setOpen(true);
    } else {
      if (gstnumber?.length < 15) {
        setmessagearr(["* GSTIN must be 15 digits"]);
        setOpen(true);
      } else {
        setIsLoading(true);
      }
    }
    const body = {
      GSTNo: gstnumber,
    };
    const response = axios
      .post(`${onboardapi}/GSTNoValidatetoken`,
       // "https://warsoftapi.warsoft.in/API/GSTNoValidatetoken/Validate",
        body
      )
      .then((res) => {
        addToLocations([]);
        
        axios
          .post(`${onboardapi}/selectstate`)
          // .post(
          //   "https://hotelonboardtestapi.staysimplyfied.com/API/selectstate"
          // )

          .then((resstate) => {
            let state_id = resstate.data.data.filter(
              (item) =>
                item.StateName === res.data?.MESSAGE?.result?.pradr?.addr?.stcd
            );

            addToLocations([
              {
                ...locationValue[0],
                Statename: res.data?.MESSAGE?.result?.pradr?.addr?.stcd,
                StateId: state_id[0]?.Id,
              },
            ]);
          })
          .catch((err) => {});

        if (res.data.MESSAGE.success === true) {
    
          setgststatus(res.data.MESSAGE.result.sts);
          setlegalname(res.data.MESSAGE.result.lgnm);
          settradename(res.data.MESSAGE.result.tradeNam);
          basic_GST_verified(res.data.MESSAGE.result);
          // setshowverifysymb(true);
          // setshowverifybtn(false);
          basic_Verifiedbut(true);
          setgsterrofmsg(false);
          setIsLoading(false);
        } else if (res.data.MESSAGE.success !== true) {
          setopengsterror(true);
          setgsterrofmsg(true);
          if (gstnumber.length === 15) {
            let gstin = document.querySelector("#gst_number");
        
            setmessagearr([
              gstin?.value.length >= 0 &&
                gstin?.value.length <= 15 &&
                "* Invalid GSTIN",
              "* It is your responsibility to review local laws and taxes. Be responsibile in paying any applicable taxes (GSTIN) on all bookings.",
            ]);

            setOpen(true);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
      
        setIsLoading(false);
      });
    
  }

  const optionscancelpolicy1 = cancelpolicyload1?.map((element, i) => {
    return {
      key: i,
      value: element.cancellationpolicy1,
      label: element.cancellationpolicy1,
    };
  });

  const optionscancelpolicy2 = cancelpolicyload2?.map((element, i) => {
    return {
      key: i,
      value: element.cancellationpolicy2,
      label: element.cancellationpolicy2,
    };
  });
  const handleCancel = () => {
    setOpen(false);
  };
  const handleCancelerror = () => {
    setopengsterror(false);
  };

  const handleChangeselect = (value) => {
   

    setServiceApp(value);
    let data = {
      propertytype: value,
    };

    const responsepropertytype = axios
      .post(`${onboardapi}/PropertytypeId`, data)
      // .post(
      //   "https://hotelonboardtestapi.staysimplyfied.com/API/PropertytypeId",
      //   data
      // )

      .then((res) => {
        

        setpropertytypeId(res.data[0].Id);
        setpropertytype(res.data[0].Id);
      });
  };
 

  //add the Others text to the last of the list
  // let ch_ch = ChannelManagerload.map((element, i) => element.Channelmanager === 'Others' ? ChannelManagerload.slice(i,1) :
  //   {

  //     value: element.Channelmanager,
  //     label: element.Channelmanager,
  // }
  // )
  let ch_ch = ChannelManagerload?.map((element, i) => {
    return {
      key: i,
      value: element.Channelmanager,
      label: element.Channelmanager,
    };
  });

  ch_ch.push({
    value: "Others",
    label: "Others",
  });
  
  //add the Others text to the last of the list

  return (
    <>
      {isLoading && (
        <div className="loaderloading">
          <div className="example">
            <Spin />{" "}
            <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
              Loading ...
            </span>
          </div>
        </div>
      )}
      <nav className="row">
        <a className="navbar-brand col-md-2 text-center mobile-show" href="#">
          <img
            className="3 mt-1"
            src={
              logo_img
            }
            alt=""
            width={"120px"}
          />
        </a>
        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon">fsfds</span>
      </button> */}
        <span className="text-center col-md-12 d-flex justify-content-center align-items-center">
          <h3>
            <b>Property Onboarding</b>
          </h3>
        </span>
      </nav>
      <form>
        <div className="container-fluid g-padding">
          <div className="row">
            <div
              className="col-md-2 no-border-l h100"
              style={{ background: "#fff", borderLeft: "none" }}
            >
              <Card className="col-md-12 h-100 side-menu">
                <Steps
                  size="small"
                  className="mt-md-5 cursor-not-allowed mobile-hide"
                  direction="vertical"
                  current={0}
                >
                  <Steps.Step title={<NavLink to="/">Basics</NavLink>} />
                  <Steps.Step
                    title={<NavLink to="/location">Location</NavLink>}
                  />
                
                 
                  <Steps.Step
                    title={<NavLink to="/facilities">Facilities</NavLink>}
                  />
                  <Steps.Step
                    title={<NavLink to="/roomdet">Room details</NavLink>}
                  />
                  <Steps.Step title={<NavLink to="/photos">Photos</NavLink>} />
                  <Steps.Step
                    title={<NavLink to="/Profile">Contact</NavLink>}
                  />
                  <Steps.Step
                    title={<NavLink to="/Publish">Publish</NavLink>}
                  />
                </Steps>
                <a
                  className="navbar-brand col-md-2 text-center logo-display"
                  href="#"
                >
                  <img
                    className="logo_name"
                    src={
                      logo_img
                    }
                    alt=""
                  />
                </a>
                <p className="mt-sm-3 deskbrowser" style={{ fontSize: "12px" }}>
                  <span
                    className="fw-bold"
                    style={{ fontSize: "14px", marginBottom: "10px" }}
                  >
                    Supported Browsers :{" "}
                  </span>
                  <br />{" "}
                  <span style={{ margin: "0px", lineHeight: "10px" }}>
                 
                    </span>
                    1. Google Chrome,
                  <br />
                  2. Microsoft Edge,
                  <br />
                  3. Firefox.
                </p>
              </Card>
            </div>
            <div
              className="col-md-10 bgcolor layout-ml"
              style={{ background: "rgb(227 245 255)" }}
            >
              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-sm-6 p-3">
                  <h3>Match your property to the right travelers.</h3>
                  <p>All information is required unless marked optional.</p>
                </div>
                <div className="col-sm-6">
                  <img src={bagpic} alt="" height={"120px"} />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-3 ">
                  <h5>
                    Property Name&nbsp;<span className="m-red">*</span>
                  </h5>
                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <Input
                      placeholder="Property Name"
                      value={propertyname}
                      onChange={(event) => {
                        
                        setpropertyname(
                         // event.target.value.replaceAll("  ", " ")
                          event.target.value.replace(/\s+/g, ' ')
                        );
                      }}
                      ref={propnameRef}
                      name="property"
                      id="prop_Name"
                      onInput={propInput}
                      // onInputChange={onInputValueChange}
                      maxLength={100}
                    />
                  </Card>
                </div>
                <div className="col-sm-9 col-md-9 col-lg-6 mt-2 mt-sm-0 service-apt">
                  <h5>Select Property Type</h5>

                  <Radio.Group
                    onChange={onChangeradio}
                    defaultValue={
                      propertytypeoption || basic[0]?.propertytypeoption
                    }
                    className="col-sm-4 float-start"
                    id="prop_type"
                  >
                    <Radio value={"Hotel"} className="col-8">
                      <b>Hotel</b>
                    </Radio>
                    {/* <p className='d-inline'>Multi-unit accommodation building with shared facilities, restaurants, and services</p> */}
                    <Radio value={"Service Apartment"}>
                      <b>Service Apartment</b>
                    </Radio>
                    <Radio value={"PG/Hostel"} className="col-8">
                      <b>PG&nbsp;/&nbsp;Hostel</b>
                    </Radio>
                    <Radio value={"Resort"} className="col-8">
                      <b>Resort</b>
                    </Radio>
                    {/* <p className='d-inline'>Apartment unit with basic services like cleaning and front desk</p> */}
                  </Radio.Group>
                  {/* <div className="col-sm-3"> */}

                  {(Show ||
                    (basic[0]?.propertytypeoption === "Hotel" &&
                      propertytypeoption === "Hotel")) && (
                    <Card
                      className="mt-2 mt-sm-0 col-12 col-sm-8 float-start"
                      style={{
                        background: "#f4f4f4",
                        boxShadow: "rgb(207 202 202) 0px 0px 10px",
                      }}
                    >
                      <p>
                        Give your home a rating to help set expectations for
                        travelers stay&nbsp;
                        <span className="m-red" style={{ fontSize: "20px" }}>
                          *
                        </span>
                      </p>
                      <Rate
                        value={propertytype}
                        // color={'blue'}
                        onChange={setpropertytype}
                      />
                    </Card>
                  )}

                  {(showserapart ||
                    (basic[0]?.propertytypeoption === "Service Apartment" &&
                      propertytypeoption === "Service Apartment")) && (
                    <Card
                      className="mt-2 mt-sm-0 col-12 col-sm-8 float-start service_apt"
                      style={{
                        background: "#f4f4f4",
                        boxShadow: "rgb(207 202 202) 0px 0px 10px",
                      }}
                    >
                      {/* <p>Give your home a rating to help set expectations for travelers stay</p>  */}
                      <Select
                        onChange={handleChangeselect}
                        style={{ width: 200 }}
                        placeholder="Select Service Apartment"
                        defaultValue={serviceApp || basic[0]?.serviceApp}
                        options={proptertytypeload.map((element, i) => {
                          return {
                            value: element.PropertyType,
                            label: element.PropertyType,
                          };
                        })}
                        id="select_ser_app"
                      />
                      &nbsp;
                      <span className="m-red" style={{ fontSize: "20px" }}>
                        *
                      </span>
                    </Card>
                  )}
                  {/* </div> */}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-12 col-md-6 col-lg-5">
                  <h5>Time</h5>

                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-sm-9">
                        Check-In
                        <div className="col-12 col-sm-12 col-lg-12 px-lg-1 px-sm-1 col-4 px-1 ps-1 ps-sm-1 date_in d-flex">
                          <Select
                            disabled={disablechktime}
                            value={checkintime}
                            placeholder="12"
                            onChange={(value) => {
                             
                              setcheckintime(value);
                            }}
                            defaultValue={checkintime}
                            className="d-inline me-2"
                            // defaultValue={
                            //   Policies1 ||
                            //   basic[0]?.Policies1 ||
                            //   "Day of arrival (6 pm)"
                            // }
                            // style={{ width: 200 }}
                            // placeholder='Select Channel Manager'
                            options={[
                              {
                                value: "01",
                                label: "01",
                              },
                              {
                                value: "02",
                                label: "02",
                              },
                              {
                                value: "03",
                                label: "03",
                              },
                              {
                                value: "04",
                                label: "04",
                              },
                              {
                                value: "05",
                                label: "05",
                              },
                              {
                                value: "06",
                                label: "06",
                              },
                              {
                                value: "07",
                                label: "07",
                              },
                              {
                                value: "08",
                                label: "08",
                              },
                              {
                                value: "09",
                                label: "09",
                              },
                              {
                                value: "10",
                                label: "10",
                              },
                              {
                                value: "11",
                                label: "11",
                              },
                              {
                                value: "12",
                                label: "12",
                              },
                            ]}
                          />
                          <Select
                            className="d-inline me-2"
                            value={checkintype}
                            placeholder="PM"
                            onChange={(value) => {
                              
                              setcheckintype(value);
                            }}
                            defaultValue={"PM"}
                            disabled={disablechktime}
                            options={[
                              {
                                value: "AM",
                                label: "AM",
                              },
                              {
                                value: "PM",
                                label: "PM",
                              },
                            ]}
                          />
                        </div>
                        <label style={{ width: "100%" }}>Check-Out</label>
                        <div className="col-12 col-sm-12 col-lg-12 px-lg-1 px-sm-1 col-4 px-1 ps-1 ps-sm-1 date_in d-flex">
                          <Select
                            onChange={(value) => {
                             
                              setcheckoutime(value);
                            }}
                            value={checkouttime}
                            disabled={disablechktime}
                            className="d-inline me-2"
                            placeholder="12"
                            defaultValue={checkouttype}
                            // id="checkout_time"
                            options={[
                              {
                                value: "01",
                                label: "01",
                              },
                              {
                                value: "02",
                                label: "02",
                              },
                              {
                                value: "03",
                                label: "03",
                              },
                              {
                                value: "04",
                                label: "04",
                              },
                              {
                                value: "05",
                                label: "05",
                              },
                              {
                                value: "06",
                                label: "06",
                              },
                              {
                                value: "07",
                                label: "07",
                              },
                              {
                                value: "08",
                                label: "08",
                              },
                              {
                                value: "09",
                                label: "09",
                              },
                              {
                                value: "10",
                                label: "10",
                              },
                              {
                                value: "11",
                                label: "11",
                              },
                              {
                                value: "12",
                                label: "12",
                              },
                            ]}
                          />
                          <Select
                            className="d-inline me-2"
                            value={checkouttype}
                            placeholder="PM"
                            disabled={disablechktime}
                            defaultValue="PM"
                            onChange={(value) => {
                              
                              setcheckouttype(value);
                            }}
                            options={[
                              {
                                value: "AM",
                                label: "AM",
                              },
                              {
                                value: "PM",
                                label: "PM",
                              },
                            ]}
                          />
                          {/* <TimePicker
                          disabled={disablechktime}
                          use12Hours
                          format="hh: A"
                          style={{ width: "100%" }}
                          onSelect={onChangecheckout}
                          // className={"mt-sm-3"}
                          value={chkouttimeupdt || basic[0]?.checkouttype}
                          placeholder="Check-Out-Time"
                          id="checkout_time"
                          showNow={false}
                          needsConfirmation={false}

                        /> */}
                        </div>
                      </div>
                      <div
                        className="col-sm-3 col-2 px-1 ms-3 ms-sm-0 mt-2 d-flex align-items-center ps-1 ps-sm-2"
                        style={{ borderLeft: "2px solid #c4c4c4" }}
                      >
                        <Checkbox
                          onChange={onChangechecktime}
                          //checked={(oneday === '24 hrs' || basic[0]?.oneday === '24 hrs') ? 'sdf' : 'fds'}
                          defaultChecked={
                            basic[0]?.oneday === "24 hrs" ? true : false
                          }
                          id="cheoneday"
                        >
                          <b>24&nbsp;hrs</b>
                        </Checkbox>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 pr-1">
                  <h5>
                    Property Description&nbsp;<span className="m-red">*</span>
                  </h5>

                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <TextArea
                      rows={1}
                      ref={propdescref}
                      value={propdescription}
                      placeholder="Property Description"
                      onChange={(event) => {
                        setpropdescription(
                          event.target.value.replaceAll("  ", " ")
                        );
                      }}
                      id="pro_descrip"
                      style={{ resize: "none" }}
                      onInput={propInput}
                      maxLength={250}
                    />
                  </Card>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-sm-6 col-lg-5 pr-1">
                  <h5>
                    GSTIN&nbsp;<span className="m-red">*</span>
                  </h5>
                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <div className="row ">
                      <div className="col-8 col-sm-8">
                        <Input
                          placeholder="GSTIN"
                          maxLength={15}
                          value={gstnumber}
                          onChange={(event) => {
                            // var letterNumber = /^[0-9a-zA-Z]+$/;
                            // var str = String.fromCharCode(!event.target.value.charCode ? event.target.value.which : event.target.value.charCode);
                            // event.target.value.match(letterNumber) &&
                            setgstnumber(event.target.value.toUpperCase());
                            basic_Verifiedbut(false);
                            setlegalname("");
                            addToLocations([]);
                          }}
                          id="gst_number"
                          onInput={propInput}
                          className="uppercasetext"
                        />
                        {contextHolder}
                      </div>
                      <div className="col-sm-4 col-2 px-0 d_v_c">
                        {/* {showverifybtn && (
                          <Button type="primary" onClick={gstnumverification}>
                            Verify
                          </Button>
                        )} 
                        {showverifysymb && (
                          <>
                            <CheckOutlined style={{ color: "green" }} />{" "}
                            <span style={{ color: "green" }}> &nbsp;Verified</span>
                          </>
                        )} */}
                        {basicverify === false ? (
                          <Button
                            type="primary"
                            className="dblclick"
                            onClick={gstnumverification}
                          >
                            Verify GSTIN
                          </Button>
                        ) : (
                          <>
                            <span className="verified_but">
                              <CheckOutlined style={{ color: "#02cc02" }} />{" "}
                              <span
                                style={{ color: "#02cc02", fontWeight: "bold" }}
                              >
                                {" "}
                                &nbsp;Verified
                              </span>
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mt-2 mt-sm-0">
                  <h5>Legal Name </h5>
                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                      height: "56px",
                    }}
                  >
                    <div className="row ">
                      <div className="col-12">
                        {(legalname || basic[0]?.legalname) && (
                          <Input
                            placeholder=""
                            maxLength={15}
                            value={legalname}
                            id="gst_legal"
                            readOnly
                          />
                        )}
                        {/* {gsterrofmsg === true && <span style={{color:'red'}}>Please check your GST number and try again</span> } */}
                      </div>
                    </div>
                  </Card>
                  {/* {legalname && (
                            <span className="mt-sm-1">
                              {legalname||"w"}{" "}
                            </span>
                          )} */}
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-sm-12 col-lg-9 pr-1 ">
                  <h5>Cancellation Policy</h5>

                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <p>
                          How many days in advance can guests cancel free of
                          charge?
                        </p>
                        <Select
                          onChange={(value) => {
                            setcancelpolicy1(value);

                            if (value == "Day of arrival (6 pm)") {
                              setPolicies1("6 PM");
                            } else {
                              setPolicies1(value);
                            }
                          }}
                          defaultValue={Policies1 || "1 day"}
                          style={{ width: 200 }}
                          // placeholder='Select Channel Manager'
                          options={optionscancelpolicy1}
                        />
                      </div>
                      <div
                        className="col-sm-12 col-md-1 d-flex justify-content-center align-items-center"
                        style={{ fontSize: "18px" }}
                      >
                        <b>Or</b>
                      </div>
                      <div className="col-sm-12 col-md-5 col-lg-4">
                        <p>guests will pay 100%</p>
                        <Select
                          onChange={(value) => {
                            setcancelpolicy2(value);
                            setPolicies2(value);
                          }}
                          style={{ width: 200 }}
                          defaultValue={Policies2 || "the room night"}
                          options={optionscancelpolicy2}
                        />
                      </div>
                    </div>

                    {/* <p>Choose one from the options below:</p> */}

                    <div className="row mt-2">
                      <div className="col-sm-12">
                        <div className="alert alert-primary mt-sm-3">
                          <span className="setcancelation">
                            The guest must cancel by {Policies1 || "1 day"} on
                            the day of arrival or pay 100% of{" "}
                            {Policies2 || "Room night."}
                          </span>
                          <br />
                          <span style={{ fontSize: "12px" }}>
                            You'll be able to make changes to your policies
                            later on – this is just to get you started.
                          </span>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-9 col-lg-6">
                  <h5>Do you work with Channel Manager</h5>

                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                      display: "flex",
                    }}
                    className="channel-manager"
                  >
                    <Radio.Group
                      id="c_manager_group"
                      // defaultValue={
                      //   channelmanageroption || basic[0]?.channelmanageroption
                      // }
                      value={
                        channelmanageroption || basic[0]?.channelmanageroption
                      }
                      onChange={onChangeradiochnlmanager}
                    >
                      <Radio value={"Yes"}>
                        <b>Yes</b>
                      </Radio>

                      <Radio value={"No"}>
                        <b>No</b>
                      </Radio>
                    </Radio.Group>
                    
                    {(showchnlmanger ||
                      channelmanageroption === "Yes" ||
                      (channelmanageroption !== "No" &&
                        basic[0]?.channelmanageroption === "Yes")) && (
                      <div className="w-75 px-0 channel_manager_select">
                        <AutoComplete
                          className="col-sm-6 text-50 channel_manager_radio"
                          defaultValue={
                            basic[0]?.Channelmanager || Channelmanager
                          }
                          onChange={(value) => {
                            
                            setotherstext("");
                            getchannelmanegerid(0);
                            const data = {
                              ChannelManager: value,
                            };
                            const responsepropertytype = axios
                              // .post(
                              //   "https://hotelonboardtestapi.staysimplyfied.com/API/Selectchannelmanagerid",
                              //   data
                              // )
                              .post(
                                `${onboardapi}/Selectchannelmanagerid`,
                                data
                              )
                              .then((res) => {
                                
                                setchnlmanagerïd(
                                  [res.data[0]?.Id].filter(
                                    (item) => item !== undefined
                                  )[0]
                                );
                              })
                              .catch((err) => {
                                
                              });
                           
                            if (value === "Others") {
                              setTextBox(true);
                            } else {
                              setTextBox(false);
                            }
                         
                            setChannelmanager(value);
                          }}
                          key={numincrement + 1}
                          style={{ width: 200 }}
                          placeholder="Select Channel Manager"
                          options={ch_ch}
                          // options={ChannelManagerload.map((element, i) => {
                          //   return {
                          //     value: element.Channelmanager,
                          //     label: element.Channelmanager,
                          //   }
                          // })}

                          filterOption={(inputValue, option) =>
                            option.value
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                        />
                        {/* (textBox ||
                          (basic[0]?.Channelmanager === "Others" &&
                            (Channelmanager === "" ||
                              Channelmanager === undefined))) */}
                       
                        {(textBox ||
                          (basic[0]?.Channelmanager === "Others" &&
                            (Channelmanager === "Others" ||
                              Channelmanager === undefined))) && (
                          <Input
                            type="text"
                            value={otherstext}
                            onChange={(event) => {
                              {
                                setotherstext(
                                  event.target.value.replaceAll("  ", " ")
                                );
                                getchannelmanegerid(0);
                              }
                            }}
                            placeholder="Enter the Channel Manager"
                            className="mt-1 ms-0 ms-md-1 col-sm-6 text-50 mx-md-1 channel_manager_text"
                            onInput={propInput}
                            maxLength={50}
                          />
                        )}
                      </div>
                    )}
                  </Card>
                </div>
                <div className="col-sm-3 col-lg-3">
                  <h5>Website Address</h5>
                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <Input
                      placeholder="Website Address"
                      onChange={(event) => {
                        const trimmedValue = event.target.value.replaceAll(" ", "");
                        setwebsiteAddress(trimmedValue);
                        //event.target.value=basic[0]?.websiteAddress
                      }}
                      value={websiteAddress}
                      id="web_site"
                      onInput={propInput}
                      maxLength={100}
                    ></Input>
                  </Card>
                </div>
              </div>

              <div className="row mt-3 mb-4">
                <div className="col-sm-12 col-lg-9">
                  <div className="float-end">
                    <Button
                      type="primary"
                      style={{ minWidth: "100px" }}
                      onClick={showModal}
                      className="dblclick"

                      // onDoubleClickCapture={}
                    >
                      Save & Continue
                      {/* <NavLink to="/location"></NavLink> */}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <footer className="footer mt-sm-auto " >
  <div className='card'>
    <div className="container ">
      <div className="card-body">
       <span className="text-muted pb-sm-1">Hummingbird • Digital Private Limited</span>
       
       <div className=" float-sm-end">
       <span className="text-muted pb-sm-1">Copywright © 2022 • All Rights Reserved</span>
       </div>
       </div>
     </div>
  </div>
</footer> */}
      </form>
      {/* <Modal
        title="Message"
        open={opengsterror1}
        onCancel={handleCancelerror}
        footer={[
          <Button key="back" type="primary" onClick={handleCancelerror}>
            Ok
          </Button>,
        ]}
      >
        {
          <p>
            It is your responsibility to review local laws and taxes. Be
            responsibile in paying any applicable taxes (GST) on all bookings.
          </p>
        }
      </Modal> */}

      <Modal
        className="error_msg"
        title="Error message"
        open={open}
        onCancel={handleCancel}
        footer={[
          <Button
            key="back"
            type="primary"
            className="dblclick"
            onClick={handleCancel}
          >
            Close
          </Button>,
        ]}
        maskClosable={false}
      >
        {errormessagearr &&
          errormessagearr.map((item, index) => <p key={index}>{item}</p>)}
      </Modal>
    </>
  );
}
